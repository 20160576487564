import {
  FooterInterface,
  MenuLinkInterface,
  FooterListInterface,
} from 'elixir-theme';
import { getModule } from 'vuex-module-decorators';
import { Component, Vue } from 'vue-property-decorator';

import { getLocalizedUrl } from '~/app/core/router';
import Menu from '~/app/core/store/modules/Menu';
import logo from '~/assets/logo-white.png';
import { SOCIAL_LINKS } from '~/utils/social';
import { DestinationModel } from '~/utils/destination';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';

@Component
export default class Footer extends Vue {
  protected menuModule: Menu | null = null;

  protected get menuDestinations(): DestinationModel[] {
    if (!this.menuModule) {
      return [];
    }

    return this.menuModule.menuDestinations;
  }

  protected get destinationLinks(): MenuLinkInterface[] {
    const menu: MenuLinkInterface[] = this.menuDestinations.map(
      (destination) => {
        return {
          name: destination.name,
          routeName: getLocalizedUrl(destination.slug, this.$router),
        };
      }
    );

    menu.push({
      name: 'Všechny destinace',
      // TODO: Localize the route properly
      routeName: getLocalizedUrl('destinace', this.$router),
    });

    return menu;
  }

  protected get footer(): FooterInterface {
    const footerData: FooterInterface = {
      headline: this.$t('app.footer.travelDesigners').toString(),
      phoneNumber: this.$t('app.contacts.phone').toString(),
      email: this.$t('app.contacts.email').toString(),
      lists: [
        {
          header: this.$t('app.footer.destinations.title').toString(),
          links: this.destinationLinks,
        },
        {
          header: this.$t('app.footer.navigation.title').toString(),
          links: [
            {
              href: true,
              name: this.$t('app.blog.title').toString(),
              routeName: this.$t('app.blog.url').toString(),
              target: '_blank',
            },
            // TODO: Fetch links from administration
            {
              name: this.$t('app.references.references').toString(),
              routeName: getLocalizedRoute(routes.references, this.$router),
            },
            {
              name: this.$t('app.faq.faq').toString(),
              routeName: getLocalizedRoute(routes.faq, this.$router),
            },
            {
              name: this.$t('app.contact.contacts').toString(),
              routeName: getLocalizedRoute(routes.contact, this.$router),
            },
            {
              name: this.$t('app.about.about').toString(),
              routeName: getLocalizedRoute(routes.about, this.$router),
            },
          ],
        },
      ],
      logoImgSrc: logo,
      copyright: this.$t('app.footer.copyright').toString(),
      bottomLinks: [
        {
          // TODO: Fetch link from administration
          name: this.$t('app.dataProtection.dataProtection').toString(),
          routeName: getLocalizedRoute(routes.dataProtection, this.$router),
        },
        {
          name: this.$t('app.cookies.settingsButton').toString(),
          onClick: this.cookieSettingsClick,
        },
      ],
    };

    const socialList: FooterListInterface = {
      header: this.$t('app.footer.social.title').toString(),
      links: [],
    };

    for (const social in SOCIAL_LINKS) {
      if (SOCIAL_LINKS.hasOwnProperty(social)) {
        const socialLink = SOCIAL_LINKS[social];
        if (!socialLink.enabled) {
          continue;
        }

        socialList.links.push({
          hasIcon: true,
          href: true,
          icon: socialLink.icon,
          name: socialLink.name,
          routeName: this.$t(socialLink.url).toString(),
          target: '_blank',
        });
      }
    }

    if (socialList.links.length > 0) {
      footerData.lists.push(socialList);
    }

    return footerData;
  }

  public created() {
    this.menuModule = getModule(Menu, this.$store);
  }

  public prefetch() {
    if (!this.menuModule) {
      return Promise.resolve();
    }

    return this.menuModule.loadHeaderMenuDestinations();
  }

  public render() {
    return (
      <footer-whole
        headline={this.footer.headline}
        phoneNumber={this.footer.phoneNumber}
        email={this.footer.email}
        lists={this.footer.lists}
        logoImgSrc={this.footer.logoImgSrc}
        copyright={this.footer.copyright}
        bottomLinks={this.footer.bottomLinks}
        onLogoClick={this.logoClick}
      />
    );
  }

  protected logoClick() {
    this.$router.push(getLocalizedRoute(routes.homepage, this.$router));
  }

  protected cookieSettingsClick(e: MouseEvent) {
    e.preventDefault();
    this.$cookieConsent.showPreferences();
  }
}
